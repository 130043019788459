import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class TNCRedirectGuardService {
    private readonly nationalCollegeUrl = 'https://nationalcollege.com';
    private readonly allowedPaths = ['compliancemanager', 'schooldocuments', 'login'];

    constructor(private router: Router, private authService: AuthService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.handleNavigation(event.url);
            }
        });
    }

    private handleNavigation(url: string): void {
        const isLoggedIn = this.authService.authenticated;
        const isPathAllowed = this.allowedPaths.some(path => new RegExp(`\\b${path}\\b`).test(url));

        if (!isLoggedIn && !isPathAllowed) {
            window.location.href = this.nationalCollegeUrl;
        }
    }
}
